import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BlockContent from '@sanity/block-content-to-react';

export const query = graphql`
  query Impress {
    sanityLaw {
      title
      _rawBody
    }
  }
`;

const Impress = props => {
  const { data } = props;

  const content = data.sanityLaw;
  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{content.title}</h2>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner">
            <section className="features">
              <BlockContent blocks={content._rawBody} />
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Impress;
